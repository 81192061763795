<template>
  <div class="checkOut">
    <h2>Lounge reservieren</h2>
    <div class="ticket-headline">
      <p class="date">
        {{ eventStart }}
      </p>
      <p class="my-2 text-primary font-medium text-xl">
        {{ item.title }}
      </p>
      <p class="my-2">
        {{ item.description }}
      </p>
    </div>
    <div class="numberOfTickets flex">
      <h3>Anzahl</h3>
      <input type="number" min="1" :max="item.quantity?item.quantity:0" v-model="quantity">
    </div>
    <hr class="separator">
    <p>Preis Lounge: {{ parseInt(item.price).toFixed(2) }}€</p>
    <div class="flex loungeCouponReservation" style="margin: 4px 0">
      <input type="checkbox" v-model="reservation" id="couponReservation">
      <label for="couponReservation">Nur Reservierung (10%)</label>
    </div>

    <p>Zwischensumme: {{ subTotal.toFixed(2) }}€</p>
    <hr class="separator">
    <coupon :items="orderItems" @coupon="c => coupon = c" />
    <hr class="separator">
    <payment-type :amount="price.netto" @total="credits => {useCredits = credits}" />
    <hr class="separator">
    <p style="font-size: 0.8em;">
      Mit dem klicken auf "Kaufen" bestätige ich, dass ich die <a class="text-primary" href="https://app4clubs.app/agb/" target="_blank">AGB</a> gelesen habe und diese akzeptiere.
    </p>
    <div style="margin: 6px 0;padding-bottom: 32px">
      <p class="error" v-if="error">
        {{ error }}
      </p>
      <button class="button-primary" type="button" @click="checkOut" :disabled="!readyState" :class="{disabled: !readyState}">
        Kaufen
      </button>
    </div>
  </div>
  <loader size="small" v-if="loading" />
</template>

<script>
import {aSyncData, dateToString, stringToDate} from "@/modules/main";
import Loader from "@/components/basic/loader";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import Coupon from "@/components/payment/coupon";
import PaymentType from "@/components/payment/paymentType";

export default {
  name: "lounges",
  components: {Loader, Coupon, PaymentType},
  props: {
    item: {
      type: Object,
      required: true
    },
    readyState: {
      type: Boolean,
      required: true
    },
    userInfo: {
      type: Object,
      required: false
    }
  },
  setup() {
    const auth = authStore()
    const config = configStore()

    return {auth, config}
  },
  data(){
    return{
      loading: false,
      coupon: {},
      useCredits: 0,
      quantity: 1,
      personal: [{
        firstname: this.userInfo.firstname,
        lastname: this.userInfo.lastname,
      }],
      error: '',
      reservation: false
    }
  },
  computed: {
    orderItems(){
      return {
        lounges: {
          [this.item.id]: {
            quantity: this.quantity,
            price: this.item.price
          }
        }
      }
    },
    subTotal(){
      return this.item.price * this.quantity * (this.reservation ? 0.1 : 1)
    },
    price(){
      const price = {}
      price.brutto = this.subTotal
      price.discount = 0

      if(this.coupon.discount){
        price.discount = this.coupon.discount
      }

      price.discount = price.discount.toFixed(2)
      price.netto = (Math.round(Math.max(0, price.brutto - price.discount) * 100) / 100).toFixed(2)
      return price
    },
    eventStart(){
      return dateToString(stringToDate(this.item.startTime))
    }
  },
  watch: {
    quantity(){
      if(this.personal.length < this.quantity){
        do {
          this.personal[this.personal.length] = {firstname: '', lastname: ''}
        }while (this.personal.length < this.quantity)
      }else if(this.personal.length > this.quantity){
        this.personal.splice(this.quantity, this.personal.length - this.quantity)
      }
    }
  },
  mounted() {
    console.log(this.item)
  },
  methods: {
    checkOut(){
      this.loading = true
      this.error = ''
      aSyncData('checkout/lounges', {
        email: this.userInfo.email,
        firstname: this.userInfo.firstname,
        lastname: this.userInfo.lastname,
        couponId: this.coupon.id ?? null,
        currentSite: this.$route.path,
        items: {lounges: [{id: this.item.id, quantity: this.quantity, reservation: this.reservation}]},
        subTotal: this.subTotal,
        total: this.price.netto,
        useCredits: this.useCredits,
        payPal: this.price.netto - this.useCredits/100
      })
          .then(r => {
            console.log(r)
            if(r.status === 250) {
              if(r.data.link) {
                location.href = r.data.link
              }else if(r.data.cipher){
                this.$router.push({name: 'Checkout', params: {cipher: r.data.cipher}})
              }
            }
            else if(r.status === 295) {
              this.auth.update()
                  .catch(e => {})
              this.error = 'Bitte erneut einlogen oder Email angeben'
            }else{
              //TODO noch auf deutsch übersetzen
              this.error = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            this.loading = false
          })
    },
  }
}
</script>

<style scoped>
  .smallHint{
    font-size: 0.8em;
  }
  .disabled,
  .disabled:hover{
    background-color: gray !important;
  }

  .checkOut h2{
    margin: 10px 0;
    font-weight: 400;
    font-size: 1.3em;
  }

  .ticket-headline{

  }

  .ticket-headline .title{
    color: #2068ff !important;
    font-size: 1.2em;
    margin: 4px 0 8px 0;
    font-weight: 600;
  }

  .ticket-headline .date {
    font-size: 0.8em;
  }

  .ticket-headline .description {
    margin: 10px;
    font-size: 1em;
  }

  .numberOfTickets{
    justify-content: center;
    column-gap: 10px;
    align-items: center;
    margin: 10px 0;
  }

  .numberOfTickets input{
    padding: 8px;
    width: 20%;
    max-width: 300px;
    border-radius: 8px;
    border: none;
    background-color: #1E1E1E;
    color: white;
  }

  .separator{
    opacity: 0.1;
    margin: 16px 0;
  }

  .loungeCouponReservation{
    justify-content: center;
    column-gap: 6px;
  }

  .button-primary{
    width: 100%;
    margin-top: 12px;
    text-transform: uppercase !important;
  }

</style>