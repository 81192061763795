<template>
  <article class="event mt-8" @click="$router.push({name: 'EventDetails', params: { id: event.id }});">
    <div class="">
      <p class="promotion" v-if="event.promotion == 1">Gesponsert</p>
      <div class="bg-gray-950 rounded-lg">
        <div class="">
          <img class="rounded-t-lg" :src="config.projectURL + 'assets/images/events/'+event.image+'.jpg'">
        </div>
        <div class="grid grid-cols-12 px-4 py-6 gap-x-3">
          <div class="col-sp-3 flex flex-wrap gap-x-96 justify-center items-center bg-purple-600 rounded-xl text-center pt-3 p-3">
            <h6 class="text-purple-300 text-sm">{{Tage[new Date(event.startTime).getDay()]}}</h6>
            <h5 class="text-2xl font-bold text-purple-200">{{ getDayFromDate(event.startTime) }}</h5>
            <h6 class="uppercase font-bold text-xs text-purple-300">{{ getMonthFromDate(event.startTime).slice(0, 3) }}</h6>
          </div>
          <div class="col-sp-9 grid items-center">
            <h4 class="text-xl md:text-xl font-bold uppercase w-full truncate">{{ event.title }}</h4>
            <div class="grid grid-cols-2 gap-x-1 md:gap-x-4 md:pt-3">
              <div class="col-sp-1">
                <button @click="$router.push({name: 'EventDetails', params: { id: event.id }});" class="w-full py-2 border-2 border-solid rounded-full hover:border-purple-600 text-md md:text-sm hover:text-purple-600 transition">
                  VIP-Lounge
                </button>
              </div>
              <div class="col-sp-1">
                <button @click="$router.push({name: 'EventDetails', params: { id: event.id }});" class="w-full py-2 border-2 border-solid rounded-full hover:border-purple-600 text-md md:text-sm hover:text-purple-600 transition">
                  Tickets
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "Event",
  props: ["event"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      eventOverview: [
        {
          title: 'AK außer Kontrolle',
          img: '',
          weekday: 'Freitag',
          date: '29',
          month: 'sep',
        },
      ],
      Tage:['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag']
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    getDayFromDate(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2];
    },
    getMonthFromDate(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      if (jsDate[1] == '01'){return 'Januar'}
      if (jsDate[1] == '02'){return 'Februar'}
      if (jsDate[1] == '03'){return 'März'}
      if (jsDate[1] == '04'){return 'April'}
      if (jsDate[1] == '05'){return 'Mai'}
      if (jsDate[1] == '06'){return 'Juni'}
      if (jsDate[1] == '07'){return 'Juli'}
      if (jsDate[1] == '08'){return 'August'}
      if (jsDate[1] == '09'){return 'September'}
      if (jsDate[1] == '10'){return 'Oktober'}
      if (jsDate[1] == '11'){return 'November'}
      if (jsDate[1] == '12'){return 'Dezember'}
    },
  }
}
</script>

<style scoped>

</style>