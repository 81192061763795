import * as Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import title from '@/mixins/title'
import { createPinia } from 'pinia'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import  {ObserveVisibility} from 'vue-observe-visibility';
//import { createI18n } from 'vue-i18n'
//import deLocale from "@/locales/de.json"
//import enLocale from "@/locales/en.json"
import {configStore} from "@/store/configStore";
import modal from "@/components/basic/modal";
import {notificationStore} from "@/store/notificationStore";
import './index.css'


const pinia = createPinia();
pinia.use(({ store }) => {
  store.$router = Vue.markRaw(router)
});

/*const i18n = new createI18n({
  locale: 'en', // set locale
  messages: {
    en: enLocale,
    de: deLocale
  }, // set locale messages
})*/

const vueProto = Vue.createApp(App)
    .use(pinia)

    .use(router)
    .use(VueAxios, axios)
    .use(Vue3Lottie)
    //.use(i18n)
    .mixin(title)

vueProto.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance;
    ObserveVisibility.bind(el, binding, vnode);
  },
  update: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind,
});


const config = configStore()
const notification = notificationStore()
vueProto.axios.defaults.baseURL = config.projectURL
vueProto.config.warnHandler = () => null;
vueProto.component('VueModal', modal)

process.env.CORDOVA_PLATFORM ? document.addEventListener('deviceready', init) : init()

function init(){
  router.isReady().then(() => vueProto.mount('#App'))
  if(config.cordova){

    universalLinks.subscribe(null, function (eventData) {
      router.push(eventData.path)
      console.log('Universallinks: ' + eventData.url);
    })

    document.addEventListener("resume", () => {
      try {
        notification.loadNotification()
      }catch (e) {}
    }, false);
  }
}

