<template>
  <main class="!p-0">

      <div class="relative">
        <div class="relative w-full h-[500px] bg-center bg-cover bg-no-repeat" style="background-image: url('/assets/images/events/eventOverviewBackground.jpg')">
          <div class="absolute inset-0" style="background-image: linear-gradient(to bottom, rgba(14, 29, 46, 0.6), rgba(14, 29, 46, 1));">
            <!-- Ihr Inhalt hier -->
          </div>
        </div>
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <h3 class="text-center text-4xl text-purple-600 font-bold">Media & Pics</h3>
        </div>
      </div>
    <div class="px-6 md:max-w-screen-xl md:m-auto pb-24">
      <media-preview></media-preview>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import events from "@/components/home/event";
import {notificationStore} from "@/store/notificationStore";
import {aSyncData, stringToDate} from "@/modules/main";
import MediaPreview from "@/components/home/mediaPreview";

export default {
  name: "Galleries",
  components: {MediaPreview},
  props: ["event"],
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data(){
    return {
      city: null,
      cityName: null,
      events: [],
      nearestEvents: [],
      page: 'start',
      cities: [],
      searchString: '',
      filterModal: false,
      filterItems: '',
      cityItems: '',
      selectedFilters: [],
      filterDate: false,

    }
  },
  computed: {
    eventsFiltered(){
      const _this = this
      let events = this.events

      if(_this.selectedFilters.length){
        events = events.filter(e => {
          return _this.selectedFilters.every(f => {
            return e.labels.split(',').some(g => {
              return parseInt(g) === parseInt(f)
            })
          })
        })
      }

      return events
    },
    nearestEventsFiltered(){
      const _this = this
      let nearestEvents = this.nearestEvents

      if(_this.selectedFilters.length){
        nearestEvents = nearestEvents.filter(e => {
          return _this.selectedFilters.every(f => {
            return e.labels.split(',').some(g => {
              return parseInt(g) === parseInt(f)
            })
          })
        })
      }

      if(_this.filterDate){
        const time = stringToDate(_this.filterDate).getTime()
        nearestEvents = nearestEvents.filter(e => {
          return stringToDate(e.startTime).getTime() <= time && stringToDate(e.endTime).getTime() >= time
        })
      }

      return nearestEvents
    }
  },
  mounted() {
    this.config.loading = false

    this.getFilterItems()
    this.getMyCities()

    if(this.$route.params.id){
      this.getEventsByCity()
    }else if(this.auth.isAuth && this.user.ipCity?.name){
      this.selectCity(this.user.ipCity)
    }else if(!this.auth.isAuth){
      this.user.getCityByIP()
          .then(r => {
            // Auskommentiert weil zu ungenau
            //this.selectCity(r)
            this.getEvents()
          })
          .catch(e => {
            this.getEvents()
          })
    }else{
      this.getEvents()
    }
  },
  methods: {
    getGPS() {
      const _this = this
      this.user.getCityByGeo()
          .then(r => {
            _this.city = r
            _this.cityName = r.name
            _this.selectCity(_this.city)
          })
          .catch(e => {
            console.log(e)
          })
    },
    selectFilter(id){
      if(this.selectedFilters.includes(id)){
        this.selectedFilters.splice(this.selectedFilters.indexOf(id), 1)
      }else{
        this.selectedFilters.push(id)
      }
    },
    getEvents(){
      const _this = this
      aSyncData('home/getEvents')
          .then(r => {
            if(r.status === 250){
              _this.events = r.data
            }
          })
          .catch(e => {})
    },
    getEventsByCity (){
      const _this = this
      aSyncData('home/getCityById', {cityId: this.$route.params.id})
          .then(r => {

            if(r.status === 250){
              _this.city = r.data
              _this.cityName = r.data.name
              this.selectCity(r.data)
            }
          })
          .catch(e => {})
    },
    getMyCities(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('limit', 3)
      this.axios.post('home/getMyCities', fD)
          .then((response) => {
            if(response.status === 250){
              _this.cityItems = response.data
            }
          })
    },
    getFilterItems(){
      const _this = this
      this.axios.post('home/getFilterItems')
          .then((response) => {
            if(response.status === 250){
              _this.filterItems = response.data
            }
          })
    },
    loadCitys(event){
      const _this = this
      this.searchString = event.input
      aSyncData('home/cities', {
        'search': this.searchString
      })
          .then(r => {
            if(r.status === 250 && _this.searchString === event.input) _this.cities = r.data
          })
          .catch(e => {
            console.error(e)
          })
    },
    selectCity(item){
      this.city = item
      this.cityName = item.name
      const _this = this
      aSyncData('home/getEventsByCity', {
        cityId: item.id
      })
          .then(r => {
            if(r.status === 250){
              _this.events = r.data.events
              _this.nearestEvents = r.data.nearestEvents
            }
          })
    },
  }
}
</script>