<template>
  <main class="!p-0">

      <div class="relative">
        <div class="relative w-full h-[500px] bg-center bg-cover bg-no-repeat" style="background-image: url('/assets/images/events/loungesOverviewBackground.jpg')">
          <div class="absolute inset-0" style="background-image: linear-gradient(to bottom, rgba(14, 29, 46, 0.6), rgba(14, 29, 46, 1));">
            <!-- Ihr Inhalt hier -->
          </div>
        </div>
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4/5 md:w-auto">
          <h3 class="text-center text-4xl text-purple-600 font-bold">Secret Club - Lounges</h3>
        </div>
      </div>
    <div class="px-6 mt-12 md:mt-16 md:max-w-screen-xl md:m-auto">
      <h3 class="text-xl font-semibold text-center pb-6">Genieße das Leben mit deinen Besten und erlebe einen exklusiven Abend in einer unserer 3 Lounges.</h3>
      <div class="md:grid md:grid-cols-2 gap-x-8  mt-4 pb-12">
        <LoungesView></LoungesView>
      </div>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {notificationStore} from "@/store/notificationStore";
import {aSyncData, stringToDate} from "@/modules/main";
import LoungesView from "@/components/lounge/lounges";

export default {
  name: "LoungesOverview",
  components: {LoungesView},
  props: ["lounge"],
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data(){
    return {

    }
  },
}
</script>