<template>
  <div class="">
    <div class="relative">
      <div class="relative w-full h-[500px] bg-center bg-cover bg-no-repeat" style="background-image: url('/assets/images/events/eventOverviewBackground.jpg')">
        <div class="absolute inset-0" style="background-image: linear-gradient(to bottom, rgba(14, 29, 46, 0.6), rgba(14, 29, 46, 1));">
          <!-- Ihr Inhalt hier -->
        </div>
      </div>
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h3 class="text-center text-4xl text-purple-600 font-bold">Fotos & Media</h3>
      </div>
    </div>
    <div class="mt-12 py-12 rounded-lg md:max-w-screen-lg md:m-auto md:mt-6 px-6 md:px-8" id="media">
      <section style="padding: 8px">
        <div class="grid-12 gap-x-4 gap-y-4">
          <router-link class="col-span-12 md:col-span-4 row-span-12" v-for="album in albums" :key="album.id" :to="`/media/album/${album.id}`" style="color:white;cursor: pointer; background-color: #00000040;border-radius: 12px;overflow: hidden">
            <div>
              <img style="width: 100%;height: auto" :src="`${config.projectURL}assets/images/media/${album.cover}_thumbnail.jpg`">
              <div style="padding: 8px;">
                <h3 style="text-align: center;font-size: 18px">{{album.title}}</h3>
                <p style="white-space: pre-line;padding: 12px; font-size: 0.9em;">{{album.description}}</p>
              </div>
            </div>
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";


export default {
  name: "MediaView",
  title: "Media",

  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      albums:[],
    }
  },
  mounted() {
    this.config.loading = false
    this.loadAlbums()
  },
  methods: {
    loadAlbums(){
      aSyncData('media/getAlbums')
          .then(r => {
            console.log(r.data)
            if(r.status === 250){
              this.albums = r.data
              console.log(this.albums)
            }
          })
    },
  }
}
</script>

<style>

main {
  background-color: unset !important;
}
main#media h1 {
  margin-top: 24px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
}
main#media h2 {
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(255,255,255,0.8);
  margin-bottom: 24px;
}
</style>