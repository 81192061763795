<template>
  <div>
    <main class="bg-gray-950 mt-12 py-12 mx-6 rounded-lg md:max-w-screen-sm md:m-auto md:mt-24 md:px-8">
      <div class="t-center pad-16">
        <h3 style="font-size: 2em;" class="f-bold color-white">📝<br>Feedback</h3>
        <h4 style="font-size: 1.3em;" class="text-primary">
          Gebe uns Feedback zu unseren Events
        </h4>
        <p class="t-center" style="margin-top: 8px;">
          Das Feedback hilft uns, um die Party noch geiler und besser zu machen.
          Vielen Dank!
        </p>
      </div>
      <form v-if="!sendState" class="pad-16" method="post" @submit.prevent="sendFeedback()">
        <div class="field simple">
          <label style="padding-bottom: 6px;">Event auswählen</label>
          <select v-model="eventId" style="margin-bottom: 16px;" required>
            <option value="-1" disabled>
              Event...
            </option>
            <option v-for="event in events" :key="event.id" :value="event.id">
              {{ event.startTime.split(' ')[0].split('-')[2] + '.' + event.startTime.split(' ')[0].split('-')[1] + '.' + event.startTime.split(' ')[0].split('-')[0] + ' - ' + event.title }}
            </option>
          </select>
        </div>
        <div class="field simple">
          <label style="padding-bottom: 6px;">Dein Feedback</label>
          <textarea v-model="description" placeholder="Dein Feedback" rows="8" required></textarea>
        </div>
        <button class="button bg-primary" style="margin-top: 16px; width: 100%;">Feedback absenden</button>
      </form>
      <div v-else class="pad-16">
        <p class="t-center color-white">
          Vielen Dank für dein Feedback! 🥳
        </p>
      </div>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "feedback",
  title: "Feedback",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      eventId: '',
      events: [],
      description: '',
      sendState: false
    }
  },
  mounted() {
    this.loadEvents()
  },
  methods:{
    loadEvents(){
      const _this = this
      this.axios.post('feedback/getEvents')
          .then((response) => {
            if(response.status === 250){
              _this.events = response.data;
            }
          })
    },
    sendFeedback(){
      const _this = this
      const fD = new FormData()
      fD.append('eventId', this.eventId)
      fD.append('description', this.description)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('feedback/sendFeedback', fD)
          .then((response) => {
            if(response.status === 250){
              _this.sendState = true
            }
          })

    },
  }
}
</script>

<style scoped>
label{
  display: block;
  font-weight: normal;
  margin-bottom: 4px;
}
.field select,
.field textarea{
  padding: 12px;
  border: none;
  border-bottom: 2px solid rgba(0,0,0,0.2);
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  border-radius: 10px;
  background-color: rgba(255,255,255,0.1);
  box-sizing: border-box;
  color: #ffffff;
}
</style>